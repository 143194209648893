import { observer } from 'mobx-react';
import React, { useCallback, useRef, useState } from 'react';

import { useStores } from '../../stores/RootStore';
import { Button } from '../../shared/components/Button';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import getValidationErrors from '../../helpers/errorsValidation';
import { Form } from '@unform/web';
import {
  faEnvelope,
  faEye,
  faEyeSlash,
  faLock,
} from '@fortawesome/free-solid-svg-icons';
import { Input } from '../../shared/components/Input';
import { Container, Content } from './style';

function PerfilComp() {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const { authStore } = useStores();

  const history = useHistory();

  const handleSubmit = useCallback(async (data: object) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string()
          .required('Email obrigatório')
          .email('Digite um email válido'),
        password: Yup.string().required('Senha obrigatória'),
        passwordConfirmation: Yup.string()
          .oneOf([Yup.ref('password'), null], 'As senhas devem corresponder')
          .required('Senha obrigatória'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const email = formRef.current?.getFieldValue('email');

      const password = formRef.current?.getFieldValue('password');

      await authStore.signIn(email, password).then(() => {
        history.push('/agendamentos');
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        return;
      }
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Container>
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input name="email" icon={faEnvelope} placeholder="E-mail" />

          <Input
            name="password"
            icon={faLock}
            iconPassword={showPassword ? faEye : faEyeSlash}
            type={showPassword ? 'text' : 'password'}
            onIconClick={() => setShowPassword(!showPassword)}
            placeholder="Senha"
          />

          <Input
            name="passwordConfirmation"
            icon={faLock}
            iconPassword={showPassword ? faEye : faEyeSlash}
            type={showPassword ? 'text' : 'password'}
            onIconClick={() => setShowPassword(!showPassword)}
            placeholder="Repetir senha"
          />

          <Button
            type="submit"
            buttonSize="medium"
            buttonType="primary"
            loading={loading}
          >
            Salvar alterações
          </Button>
        </Form>
      </Content>
    </Container>
  );
}

export const Perfil = observer(PerfilComp);
