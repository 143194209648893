import { action, observable } from 'mobx';
import api from '../api';
import { AgendamentosList } from '../helpers/interfaces';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../helpers/notification';
import { RootStore } from './RootStore';

export class AgendamentosStore {
  @observable
  public agendamentosList: AgendamentosList | null = null;

  protected rootStore: RootStore;

  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action
  private setAgendamentosList(agendamentos: AgendamentosList | null) {
    this.agendamentosList = agendamentos;
  }

  @action
  public async getAgendamentosList(
    currentPage: number,
    pageSize: number,
    search?: string,
  ) {
    try {
      const agendamentosList = (
        await api.get(`/schedules`, {
          params: {
            currentPage,
            pageSize,
            search,
          },
        })
      ).data;
      this.setAgendamentosList(agendamentosList);
    } catch (error) {
      showErrorNotification('Erro ao listar os agendamentos!');
      showErrorNotification(error?.response?.data?.message);

      if (error?.response?.data?.message === 'jwt expired') {
        this.rootStore.authStore.logout();
      }
    }
  }

  @action
  public async addAgendamentoDiario(
    title: string,
    contacts: { id: string }[],
    audio: { id: string },
    allContacts: boolean,
    frequency: string,
    time: string,
    date: string,
    deleteWeekend: boolean,
    activated: boolean,
  ) {
    try {
      await api.post('/schedules', {
        title,
        contacts,
        allContacts,
        audio,
        frequency,
        time,
        date,
        deleteWeekend,
        activated,
      });
      showSuccessNotification('Agendamento adicionado com sucesso!');
    } catch (error) {
      showErrorNotification('Erro ao adicionar agendamento!');
      showErrorNotification(error?.response?.data?.message);
    }
  }

  @action
  public async editAgendamentoDiario(
    id: string,
    title: string,
    contacts: { id: string }[],
    audio: { id: string },
    allContacts: boolean,
    frequency: string,
    time: string,
    date: string,
    deleteWeekend: boolean,
    activated: boolean,
  ) {
    try {
      await api.put(`/schedules/${id}`, {
        title,
        contacts,
        allContacts,
        audio,
        frequency,
        time,
        date,
        deleteWeekend,
        activated,
      });
      showSuccessNotification('Agendamento editado com sucesso!');
    } catch (error) {
      showErrorNotification('Erro ao editar agendamento!');
      showErrorNotification(error?.response?.data?.message);
    }
  }

  @action
  public async addAgendamentoSemanal(
    title: string,
    contacts: { id: string }[],
    audio: { id: string },
    allContacts: boolean,
    frequency: string,
    time: string,
    weekDay: string,
    date: string,
    activated: boolean,
  ) {
    try {
      await api.post('/schedules', {
        title,
        contacts,
        allContacts,
        audio,
        frequency,
        time,
        weekDay,
        date,
        activated,
      });
      showSuccessNotification('Agendamento adicionado com sucesso!');
    } catch (error) {
      showErrorNotification('Erro ao adicionar agendamento!');
      showErrorNotification(error?.response?.data?.message);
    }
  }

  @action
  public async editAgendamentoSemanal(
    id: string,
    title: string,
    contacts: { id: string }[],
    audio: { id: string },
    allContacts: boolean,
    frequency: string,
    time: string,
    weekDay: string,
    date: string,
    activated: boolean,
  ) {
    try {
      await api.put(`/schedules/${id}`, {
        title,
        contacts,
        allContacts,
        audio,
        frequency,
        time,
        weekDay,
        date,
        activated,
      });
      showSuccessNotification('Agendamento editado com sucesso!');
    } catch (error) {
      showErrorNotification('Erro ao editar agendamento!');
      showErrorNotification(error?.response?.data?.message);
    }
  }

  @action
  public async addAgendamentoMensal(
    title: string,
    contacts: { id: string }[],
    audio: { id: string },
    allContacts: boolean,
    frequency: string,
    time: string,
    date: string,
    activated: boolean,
  ) {
    try {
      await api.post('/schedules', {
        title,
        contacts,
        allContacts,
        audio,
        frequency,
        time,
        date,
        activated,
      });
      showSuccessNotification('Agendamento adicionado com sucesso!');
    } catch (error) {
      showErrorNotification('Erro ao adicionar agendamento!');
      showErrorNotification(error?.response?.data?.message);
    }
  }

  @action
  public async editAgendamentoMensal(
    id: string,
    title: string,
    contacts: { id: string }[],
    audio: { id: string },
    allContacts: boolean,
    frequency: string,
    time: string,
    date: string,
    activated: boolean,
  ) {
    try {
      await api.put(`/schedules/${id}`, {
        title,
        contacts,
        allContacts,
        audio,
        frequency,
        time,
        date,
        activated,
      });
      showSuccessNotification('Agendamento editado com sucesso!');
    } catch (error) {
      showErrorNotification('Erro ao editar agendamento!');
      showErrorNotification(error?.response?.data?.message);
    }
  }

  @action
  public async addAgendamentoAnual(
    title: string,
    contacts: { id: string }[],
    audio: { id: string },
    allContacts: boolean,
    frequency: string,
    time: string,
    date: string,
    activated: boolean,
  ) {
    try {
      await api.post('/schedules', {
        title,
        contacts,
        allContacts,
        audio,
        frequency,
        time,
        date,
        activated,
      });
      showSuccessNotification('Agendamento adicionado com sucesso!');
    } catch (error) {
      showErrorNotification('Erro ao adicionar agendamento!');
      showErrorNotification(error?.response?.data?.message);
    }
  }

  @action
  public async editAgendamentoAnual(
    id: string,
    title: string,
    contacts: { id: string }[],
    audio: { id: string },
    allContacts: boolean,
    frequency: string,
    time: string,
    date: string,
    activated: boolean,
  ) {
    try {
      await api.put(`/schedules/${id}`, {
        title,
        contacts,
        allContacts,
        audio,
        frequency,
        time,
        date,
        activated,
      });
      showSuccessNotification('Agendamento editado com sucesso!');
    } catch (error) {
      showErrorNotification('Erro ao editar agendamento!');
      showErrorNotification(error?.response?.data?.message);
    }
  }

  @action
  public async addAgendamentoCustomizado(
    title: string,
    contacts: { id: string }[],
    audio: { id: string },
    allContacts: boolean,
    frequency: string,
    time: string,
    date: string,
    activated: boolean,
  ) {
    try {
      await api.post('/schedules', {
        title,
        contacts,
        allContacts,
        audio,
        frequency,
        time,
        date,
        activated,
      });
      showSuccessNotification('Agendamento adicionado com sucesso!');
    } catch (error) {
      showErrorNotification('Erro ao adicionar agendamento!');
      showErrorNotification(error?.response?.data?.message);
    }
  }

  @action
  public async editAgendamentoCustomizado(
    id: string,
    title: string,
    contacts: { id: string }[],
    audio: { id: string },
    allContacts: boolean,
    frequency: string,
    time: string,
    date: string,
    activated: boolean,
  ) {
    try {
      await api.put(`/schedules/${id}`, {
        title,
        contacts,
        allContacts,
        audio,
        frequency,
        time,
        date,
        activated,
      });
      showSuccessNotification('Agendamento editado com sucesso!');
    } catch (error) {
      showErrorNotification('Erro ao editar agendamento!');
      showErrorNotification(error?.response?.data?.message);
    }
  }

  @action
  public async getAgendamento(agendamentoId: string) {
    try {
      await api.get(`schedules/${agendamentoId}`);
    } catch (error) {
      showErrorNotification('Erro ao buscar agendamento');
      showErrorNotification(error?.response?.data?.message);
    }
  }

  public async deleteAgendamento(agendamentoId: string) {
    try {
      await api.delete(`schedules/${agendamentoId}`);
      showSuccessNotification('Agendamento deletado com sucesso!');
    } catch (error) {
      showErrorNotification('Erro ao excluir agendamento!');
      showErrorNotification(error?.response?.data?.message);
    }
  }
}
