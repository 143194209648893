import React, { useState, useEffect } from 'react';
import { Loading } from '../../shared/components/Loading';
import { Modal } from '../../shared/components/Modal';
import * as S from './styles';

export interface ModalAutenticarWhatsAppProps {
  closeModal(): void;
  visible: boolean;
  QrCodeLoading: boolean;
}

export function ModalAutenticarWhatsApp({
  visible,
  closeModal,
  QrCodeLoading,
}: ModalAutenticarWhatsAppProps) {
  return (
    <Modal
      visible={visible}
      title="Autenticar WhatsApp"
      closeModal={closeModal}
    >
      {QrCodeLoading ? (
        <Loading size={16} />
      ) : (
        <>
          <S.Canvas QrCodeLoading={QrCodeLoading} id="qrcode" />
        </>
      )}
    </Modal>
  );
}

export default ModalAutenticarWhatsApp;
