import { action, observable } from 'mobx';
import { AudiosList } from '../helpers/interfaces';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../helpers/notification';
import { RootStore } from './RootStore';
import api from '../api';

export class AudiosStore {
  @observable
  public audiosList: AudiosList | null = null;

  protected rootStore: RootStore;

  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action
  private setAudiosList(audios: AudiosList | null) {
    this.audiosList = audios;
  }

  @action
  public async getAudiosList(
    currentPage?: number,
    pageSize?: number,
    search?: string,
  ) {
    try {
      const audiosList = (
        await api.get(`/audios`, {
          params: {
            currentPage,
            pageSize,
            search,
          },
        })
      ).data;
      this.setAudiosList(audiosList);
    } catch (error) {
      showErrorNotification('Erro ao listar os áudios!');
      showErrorNotification(error?.response?.data?.message);

      if (error?.response?.data?.message === 'jwt expired') {
        this.rootStore.authStore.logout();
      }
    }
  }

  public async addAudio(audio: FormData) {
    try {
      await api.post(`/audios`, audio, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      showSuccessNotification('Áudio adicionado com sucesso!');
    } catch (error) {
      showErrorNotification('Erro ao adicionar áudio');
      showErrorNotification(error?.response?.data?.message);
    }
  }

  @action
  public async editAudio(audioId: string, audio?: FormData) {
    try {
      await api.patch(`audios/${audioId}/file`, audio, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      showSuccessNotification('Áudio editado com sucesso!');
    } catch (error) {
      showErrorNotification('Erro ao editar áudio!');
      showErrorNotification(error?.response?.data?.message);
    }
  }

  @action
  public async editTitleAudio(audioId: string, title?: string) {
    try {
      await api.patch(`audios/${audioId}`, {
        title,
      });

      showSuccessNotification('Título do áudio editado com sucesso!');
    } catch (error) {
      showErrorNotification('Erro ao editar título do áudio!');
      showErrorNotification(error?.response?.data?.message);
    }
  }

  @action
  public async getAudio(audioId: string) {
    try {
      await api.get(`audios/${audioId}`);
    } catch (error) {
      showErrorNotification('Erro ao buscar áudio');
      showErrorNotification(error?.response?.data?.message);
    }
  }

  public async deleteAudio(audioId: string) {
    try {
      await api.delete(`audios/${audioId}`);
      showSuccessNotification('Áudio deletado com sucesso!');
    } catch (error) {
      showErrorNotification('Erro ao excluir áudio!');
      showErrorNotification(error?.response?.data?.message);
    }
  }
}
