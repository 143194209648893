import React, { useEffect, useState } from 'react';
import { useStores } from '../../../stores/RootStore';
import { Button } from '../../../shared/components/Button';
import { InputSimple } from '../../../shared/components/InputSimple';
import {
  FormContainer,
  Section,
  SectionContact,
  SectionDate,
  SectionFrequency,
  SectionMonthly,
  SectionPause,
  SectionSelected,
  SectionWeekday,
  SectionWeekend,
} from './style';
import { Modal } from '../../../shared/components/Modal';
import { DatePicker, Select, Switch, TimePicker } from 'antd';
import moment, { Moment } from 'moment';
import { translateMonth, translateWeekday } from '../../../helpers/translates';
import { DataSourceAgendamento } from '../../../helpers/interfaces';
import {
  optionsDiaDaSemana,
  optionsPeriodicidade,
} from '../../../helpers/options';
import { getDateEnglish, getMonth, getMonthDay } from '../../../helpers/utils';

export interface ModalEditAgendamentoProps {
  visible: boolean;
  closeModal(): void;
  fetch(): void;
  agendamento?: DataSourceAgendamento;
}

export function ModalEditAgendamento({
  visible,
  closeModal,
  fetch,
  agendamento,
}: ModalEditAgendamentoProps) {
  const [isLoading, setIsLoading] = useState(false);
  const id = agendamento?.key ?? '';
  const [title, setTitle] = useState<string>(agendamento?.title ?? '');
  const [contatosSelected, setContatosSelected] = useState<string[]>(
    agendamento?.contacts ?? [],
  );
  const [allContacts, setAllContacts] = useState(
    agendamento?.allContacts ?? false,
  );
  const [audioSelected, setAudioSelected] = useState<string>(
    agendamento?.audio ?? '',
  );
  const [timeSelected, setTimeSelected] = useState<string>(
    agendamento?.time ?? '',
  );
  const [periodicidadeSelected, setPeriodicidadeSelected] = useState<string>(
    agendamento?.frequency ?? '',
  );
  const [deleteWeekend, setDeleteWeekend] = useState<boolean>(
    agendamento?.deleteWeekend ?? true,
  );
  const [activated, setActivated] = useState<boolean>(
    !agendamento?.activated ?? true,
  );
  const [weekdaySelected, setWeekdaySelected] = useState<string>(
    agendamento?.weekDay ?? 'mon',
  );
  const [monthDaySelected, setMonthDaySelected] = useState<number>(
    parseInt(getMonthDay(agendamento?.date ?? ''), 10) ?? 1,
  );
  const [monthSelected, setMonthSelected] = useState<string>(
    getMonth(agendamento?.date ?? '') ?? '',
  );

  const [dateSelected, setDateSelected] = useState<string>(
    agendamento?.date ?? '',
  );

  const [loading, setLoading] = useState(false);

  const { agendamentosStore, contatosStore, audiosStore } = useStores();

  const [valueTime, setValueTime] = useState<Moment | null>(
    moment(`${agendamento?.date} ${agendamento?.time}`, 'DD/MM/YYYY HH:mm'),
  );
  const [valueDate, setValueDate] = useState<Moment | null>(
    moment(`${agendamento?.date}`, 'DD/MM/YYYY'),
  );

  const [valueMonthDay, setValueMonthDay] = useState<Moment | null>(
    moment(`${agendamento?.date} ${agendamento?.time}`, 'DD/MM/YYYY HH:mm'),
  );

  const [valuemonth, setValueMonth] = useState<Moment | null>(
    moment(`${agendamento?.date} ${agendamento?.time}`, 'DD/MM/YYYY HH:mm'),
  );

  const { Option } = Select;

  const [contatos, setContatos] = useState<string[]>([]);
  const [optionsContatos, setOptionsContatos] = useState<JSX.Element[]>([]);
  const [optionsAudios, setOptionsAudios] = useState<JSX.Element[]>([]);

  const currentTime = moment(new Date()).format('YYYY-MM-DD');
  const currentYear = moment(new Date()).format('/YYYY');
  const currentMonthAndYear = moment(new Date()).format('/MM/YYYY');

  const validateButton = title;

  async function submitEditAgendamento() {
    setIsLoading(true);

    const audio = {
      id: audioSelected ?? '',
    };

    const contacts = contatosSelected.map(contact => ({
      id: contact,
    }));

    if (periodicidadeSelected === 'daily') {
      await agendamentosStore.editAgendamentoDiario(
        id,
        title,
        contacts,
        audio,
        allContacts,
        periodicidadeSelected ?? '',
        timeSelected ?? '',
        currentTime,
        deleteWeekend,
        activated,
      );
    }

    if (periodicidadeSelected === 'weekly') {
      await agendamentosStore.editAgendamentoSemanal(
        id,
        title,
        contacts,
        audio,
        allContacts,
        periodicidadeSelected,
        timeSelected ?? '',
        weekdaySelected ?? '',
        currentTime,
        activated,
      );
    }

    if (periodicidadeSelected === 'monthly') {
      await agendamentosStore.editAgendamentoMensal(
        id,
        title,
        contacts,
        audio,
        allContacts,
        periodicidadeSelected,
        timeSelected ?? '',
        getDateEnglish(dateSelected),
        activated,
      );
    }

    if (periodicidadeSelected === 'yearly') {
      await agendamentosStore.editAgendamentoAnual(
        id,
        title,
        contacts,
        audio,
        allContacts,
        periodicidadeSelected,
        timeSelected ?? '',
        getDateEnglish(dateSelected),
        activated,
      );
    }

    if (periodicidadeSelected === 'single') {
      await agendamentosStore.editAgendamentoCustomizado(
        id,
        title,
        contacts,
        audio,
        allContacts,
        periodicidadeSelected,
        timeSelected ?? '',
        getDateEnglish(dateSelected),
        activated,
      );
    }

    fetch();
    setIsLoading(false);
    closeModal();
  }

  async function getContatos() {
    setLoading(true);
    await contatosStore.getContatosList().finally(() => {
      setLoading(false);

      const DataSourceContatos = contatosStore.contatosList?.data.contacts.map(
        contato => {
          return (
            <Option key={contato.id} value={contato.id}>
              {contato.name}
            </Option>
          );
        },
      );

      setOptionsContatos(DataSourceContatos ?? []);
    });
  }

  async function getAudios() {
    setLoading(true);
    await audiosStore.getAudiosList().finally(() => {
      setLoading(false);

      const DataSourceAudios = audiosStore.audiosList?.data.audios.map(
        audio => {
          return (
            <Option key={audio.id} value={audio.id}>
              {audio.title}
            </Option>
          );
        },
      );

      setOptionsAudios(DataSourceAudios ?? []);
    });
  }

  useEffect(() => {
    setLoading(true);
    getContatos();
    getAudios();
  }, []);

  return (
    <Modal visible={visible} title="Editar agendamento" closeModal={closeModal}>
      <FormContainer>
        <Section>
          <p>Título</p>
          <InputSimple
            value={title}
            name="title"
            handleOnChange={value => setTitle(value)}
          />
        </Section>

        <SectionContact>
          <p>Contatos</p>
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Selecione os contatos"
            defaultValue={contatosSelected}
            onChange={value => setContatosSelected(value)}
            loading={loading}
          >
            {optionsContatos}
          </Select>
        </SectionContact>

        <SectionPause>
          <p>Todos os contatos</p>
          <Switch
            checked={allContacts}
            onChange={value => {
              setAllContacts(value);
              setContatosSelected(contatos);
            }}
          />
        </SectionPause>

        <SectionFrequency>
          <p>Áudio</p>
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Selecione o áudio"
            defaultValue={audioSelected}
            onChange={(value: string) => setAudioSelected(value)}
            loading={loading}
          >
            {optionsAudios}
          </Select>
        </SectionFrequency>

        <SectionFrequency>
          <p>Periodicidade</p>
          <Select
            value={periodicidadeSelected}
            showSearch
            style={{ width: '100%' }}
            placeholder="Selecione a periodicidade"
            onChange={(value: string) => setPeriodicidadeSelected(value)}
          >
            {optionsPeriodicidade.map(option => (
              <Option key={option.key} value={option.value} defaultValue>
                {option.key}
              </Option>
            ))}
          </Select>
        </SectionFrequency>

        {periodicidadeSelected === 'daily' && (
          <SectionWeekend>
            <p>Excluir final de semana</p>
            <Switch
              checked={deleteWeekend}
              onChange={value => setDeleteWeekend(value)}
            />
          </SectionWeekend>
        )}

        {periodicidadeSelected === 'weekly' && (
          <SectionWeekday>
            <Select
              showSearch
              value={weekdaySelected}
              style={{ width: '100%' }}
              placeholder="Selecione o dia da semana"
              onChange={(value: string) => setWeekdaySelected(value)}
            >
              {optionsDiaDaSemana.map(option => (
                <Option key={option.key} value={option.value}>
                  {option.key}
                </Option>
              ))}
            </Select>
          </SectionWeekday>
        )}

        {periodicidadeSelected === 'monthly' && (
          <SectionMonthly>
            <DatePicker
              value={valueMonthDay}
              placeholder="Selecione o dia no mês"
              onChange={(value, dateString) => {
                setValueMonthDay(value);
                setDateSelected(dateString.concat(currentMonthAndYear));
                setMonthDaySelected(parseInt(dateString, 10));
              }}
              format="DD"
            />
          </SectionMonthly>
        )}

        {periodicidadeSelected === 'yearly' && (
          <SectionMonthly>
            <DatePicker
              value={valuemonth}
              placeholder="Selecione o dia e mês"
              onChange={(value, dateString) => {
                setValueMonth(value);
                setDateSelected(dateString.concat(currentYear));
                setMonthDaySelected(parseInt(dateString.substring(0, 2), 10));
                setMonthSelected(dateString.substring(3, 5));
              }}
              format="DD/MM"
            />
          </SectionMonthly>
        )}

        {periodicidadeSelected === 'custom' && (
          <SectionMonthly>
            <DatePicker
              value={valueDate}
              placeholder="Selecione o ano, dia e mês"
              onChange={(value, dateString) => {
                setValueDate(value);
                setDateSelected(dateString);
              }}
              format="DD/MM/YYYY"
            />
          </SectionMonthly>
        )}

        <SectionDate>
          <TimePicker
            value={valueTime}
            onChange={(value, dateString) => {
              setValueTime(value);
              setTimeSelected(dateString);
            }}
            format="HH:mm"
          />
        </SectionDate>

        <>
          {periodicidadeSelected === 'daily' &&
            timeSelected &&
            !deleteWeekend && (
              <SectionSelected>
                {`Todo dia, às ${timeSelected}.`}
              </SectionSelected>
            )}

          {periodicidadeSelected === 'daily' &&
            timeSelected &&
            deleteWeekend && (
              <SectionSelected>
                {`Todo dia, às ${timeSelected}, exceto fins de semana.`}
              </SectionSelected>
            )}

          {periodicidadeSelected === 'weekly' &&
            timeSelected &&
            weekdaySelected && (
              <SectionSelected>
                {`${translateWeekday(weekdaySelected)}, às ${timeSelected}.`}
              </SectionSelected>
            )}

          {periodicidadeSelected === 'monthly' &&
            timeSelected &&
            monthDaySelected && (
              <SectionSelected>
                {`Todo mês, dia ${monthDaySelected}, às ${timeSelected}.`}
              </SectionSelected>
            )}

          {periodicidadeSelected === 'yearly' &&
            timeSelected &&
            monthSelected && (
              <SectionSelected>
                {`Todo ano, dia ${monthDaySelected} de ${translateMonth(
                  monthSelected,
                )}, às ${timeSelected}.`}
              </SectionSelected>
            )}

          {periodicidadeSelected === 'single' &&
            timeSelected &&
            dateSelected && (
              <SectionSelected>
                {`Uma única vez, dia ${dateSelected}, às ${timeSelected}.`}
              </SectionSelected>
            )}
        </>

        <SectionPause>
          <p>Pausar agendamento?</p>
          <Switch checked={activated} onChange={value => setActivated(value)} />
        </SectionPause>

        <Button
          buttonSize="medium"
          buttonType="primary"
          type="submit"
          loading={isLoading}
          disabled={!validateButton}
          onClick={() => submitEditAgendamento()}
        >
          Confirmar
        </Button>
      </FormContainer>
    </Modal>
  );
}
