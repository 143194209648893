import { action, observable } from 'mobx';
import { ContatosList } from '../helpers/interfaces';
import api from '../api';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../helpers/notification';
import { RootStore } from './RootStore';

export class ContatosStore {
  @observable
  public contatosList: ContatosList | null = null;

  protected rootStore: RootStore;

  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action
  private setContatosList(contatos: ContatosList | null) {
    this.contatosList = contatos;
  }

  @action
  public async getContatosList(
    currentPage?: number,
    pageSize?: number,
    search?: string,
  ) {
    try {
      const contatosList = (
        await api.get(`/contacts`, {
          params: {
            currentPage,
            pageSize,
            search,
          },
        })
      ).data;
      this.setContatosList(contatosList);
    } catch (error) {
      showErrorNotification('Erro ao listar os contatos!');
      showErrorNotification(error?.response?.data?.message);

      if (error?.response?.data?.message === 'jwt expired') {
        this.rootStore.authStore.logout();
      }
    }
  }

  @action
  public async addContato(name: string, phoneNumber: string) {
    try {
      await api.post('contacts', {
        name,
        phoneNumber,
      });
      showSuccessNotification('Contato adicionado com sucesso!');
    } catch (error) {
      showErrorNotification('Erro ao adicionar contato!');
      showErrorNotification(error?.response?.data?.message);
    }
  }

  @action
  public async editContato(
    contatoId: string,
    name: string,
    phoneNumber: string,
  ) {
    try {
      await api.put(`contacts/${contatoId}`, {
        name,
        phoneNumber,
      });
      showSuccessNotification('Contato editado com sucesso!');
    } catch (error) {
      showErrorNotification('Erro ao editar contato!');
      showErrorNotification(error?.response?.data?.message);
    }
  }

  @action
  public async getContato(contatoId: string) {
    try {
      await api.get(`contacts/${contatoId}`);
    } catch (error) {
      showErrorNotification('Erro ao buscar contato');
      showErrorNotification(error?.response?.data?.message);
    }
  }

  public async deleteContato(contatoId: string) {
    try {
      await api.delete(`contacts/${contatoId}`);
      showSuccessNotification('Contato deletado com sucesso!');
    } catch (error) {
      showErrorNotification('Erro ao excluir contato!');
      showErrorNotification(error?.response?.data?.message);
    }
  }
}
