import styled from 'styled-components';

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 6px;

    p {
      color: var(--purple-400);
      margin: 0 12px 0 6px;
    }
  }
`;

export const CurrentPage = styled.span`
  font-weight: bold;
  color: var(--black);
  margin-right: 6px;
  margin: 0 6px 0 12px;
  font-size: 20px;
`;
