// eslint-disable-next-line import/no-extraneous-dependencies
import { observer } from 'mobx-react-lite';
import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode';

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Agendamentos } from '../containers/Agendamentos';
import { Audios } from '../containers/Audios';
import { Contatos } from '../containers/Contatos';
import { Perfil } from '../containers/Perfil';
import { Sidenav } from '../shared/components/Sidenav';
import { Login } from '../shared/containers/Login';
import { useStores } from '../stores/RootStore';
import { Container, Content } from './style';
import ModalAutenticarWhatsApp from '../containers/ModalAutenticarWhatsApp';
import { LOCAL_STORAGE_ACCESS_TOKEN } from '../helpers/localStorage';

const token = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);

function RoutesComp() {
  const { authStore } = useStores();
  const [QrCodeloading, setQrCodeloading] = useState(false);
  const [modalAutenticarWhatsApp, setModalAutenticarWhatsApp] =
    useState<boolean>(false);
  const [listening, setListening] = useState<boolean>(false);
  useEffect(() => {
    if (authStore.isUserLoggedIn) {
      if (!listening) {
        console.log('listening');
        /*       const events = new EventSource(process.env.REACT_APP_API_URL as string); */
        const events = new WebSocket(`wss://agendabot-api.titanci.com.br/ws`);

        events.onmessage = event => {
          console.log('message event: ');
          const parsedData = JSON.parse(event.data);
          console.log('parsedData: ', parsedData);
          const { data } = parsedData;

          if (parsedData.event === 'disconnected') {
            setModalAutenticarWhatsApp(true);
            setQrCodeloading(true);
          } else if (parsedData.event === 'qr') {
            if (data !== '') {
              setModalAutenticarWhatsApp(true);
              setQrCodeloading(false);
              QRCode.toCanvas(
                document.getElementById('qrcode'),
                data,
                error => {
                  if (error) console.error(error);
                },
              );
            } else {
              console.error(
                'ops, o qr code está com problemas. Tente Novamente.',
              );
            }
          } else if (parsedData.event === 'auth_failure') {
            console.error(
              'ops, Aconteceu algum erro na autenticação do whatspp. Tente Novamente.',
            );
          } else {
            console.log('autenticado com sucesso');
            setModalAutenticarWhatsApp(false);
          }
        };
        setListening(true);
      }
    }
  }, [listening]);
  if (authStore.isUserLoggedIn) {
    return (
      <Switch>
        <Container>
          <Sidenav />
          <Content>
            <Route path="/perfil" component={Perfil} />
            <Route path="/agendamentos" component={Agendamentos} />
            <Route path="/contatos" component={Contatos} />
            <Route path="/audios" component={Audios} />
          </Content>
          {modalAutenticarWhatsApp && (
            <ModalAutenticarWhatsApp
              visible={modalAutenticarWhatsApp}
              closeModal={() => setModalAutenticarWhatsApp(false)}
              QrCodeLoading={QrCodeloading}
            />
          )}
        </Container>
      </Switch>
    );
  }

  return (
    <BrowserRouter>
      <Route path="*" render={() => <Redirect to="/login" />} />
      <Route path="/login" component={Login} />
      <Route exact path="/" render={() => <Redirect to="/login" />} />
    </BrowserRouter>
  );
}

export const Routes = observer(RoutesComp);
