export function translateWeekday(value: string) {
  switch (value) {
    case 'mon':
      return 'Toda segunda';
    case 'tue':
      return 'Toda terça';
    case 'wed':
      return 'Toda quarta';
    case 'thu':
      return 'Toda quinta';
    case 'fri':
      return 'Toda sexta';
    case 'sat':
      return 'Todo sábado';
    case 'sun':
      return 'Todo domingo';
    default:
      return 'Houve algum erro';
  }
}

export function translateMonth(value: string) {
  console.log(value);
  switch (value) {
    case '01':
      return 'janeiro';
    case '02':
      return 'fevereiro';
    case '03':
      return 'março';
    case '04':
      return 'abril';
    case '05':
      return 'maio';
    case '06':
      return 'junho';
    case '07':
      return 'julho';
    case '08':
      return 'agosto';
    case '09':
      return 'setembro';
    case '10':
      return 'outubro';
    case '11':
      return 'novembro';
    case '12':
      return 'dezembro';
    default:
      return 'Houve algum erro';
  }
}
