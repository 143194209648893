import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import QRCode from 'qrcode';
import { debounceSearch } from '../../helpers/search';
import { Search } from '../../shared/components/Search';
import { useStores } from '../../stores/RootStore';
import { DataSourceAgendamento } from '../../helpers/interfaces';
import { Button } from '../../shared/components/Button';
import {
  Actions,
  AgendamentosContainer,
  AgendamentosContentButton,
  AgendamentosContentTable,
} from './style';
import { Table, Tag } from 'antd';
import { ModalAddAgendamento } from './ModalAddAgendamento';
import { translateMonth, translateWeekday } from '../../helpers/translates';
import { ModalConfirmation } from '../../shared/components/ModalConfirmation';
import { ModalEditAgendamento } from './ModalEditAgendamento';
import { ModalAutenticarWhatsApp } from '../ModalAutenticarWhatsApp';
import { Pagination } from '../../shared/components/Pagination';
import { getDateBrazilian, getMonth, getMonthDay } from '../../helpers/utils';

function AgendamentosComp() {
  const [search, setSearch] = useState('');
  const [agendamentosData, setAgendamentosData] = useState<
    DataSourceAgendamento[]
  >([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [QrCodeloading, setQrCodeloading] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalAutenticarWhatsApp, setModalAutenticarWhatsApp] =
    useState<boolean>(false);

  const [listening, setListening] = useState<boolean>(false);

  const [agendamentoSelected, setAgendamentoSelected] =
    useState<DataSourceAgendamento>();

  const debounceQuery = debounceSearch(500);

  const { agendamentosStore } = useStores();

  const pageSize = 6;

  async function fetchAgendamentosData(searchName?: string) {
    setLoading(true);
    await agendamentosStore
      .getAgendamentosList(currentPage, pageSize, searchName)
      .finally(() => {
        setLoading(false);

        const DataSource =
          agendamentosStore.agendamentosList?.data.schedules.map(
            agendamento => {
              return {
                key: agendamento.id,
                title: agendamento.title,
                allContacts: agendamento?.allContacts,
                contacts: agendamento?.contacts.map(contact => contact.id),
                audio: agendamento?.audio?.id,
                frequency: agendamento.frequency,
                time: agendamento.time,
                activated: agendamento?.activated,
                date: getDateBrazilian(agendamento?.date),
                deleteWeekend: agendamento?.deleteWeekend,
                weekDay: agendamento?.weekDay,
              };
            },
          );

        setAgendamentosData(DataSource ?? []);
      });
  }

  async function handleSearch(value: string) {
    setSearch(value);
    fetchAgendamentosData(value);
  }

  async function deleteAgendamento(id: string) {
    setLoading(true);
    await agendamentosStore.deleteAgendamento(id);
    fetchAgendamentosData();
    setModalDelete(false);
  }

  /* useEffect(() => {
    setQrCodeloading(true);
    fetchAgendamentosData(search);
  }, [currentPage]);

  useEffect(() => {
    if (!listening) {
      console.log('listening');

      const events = new EventSource(
        'https://agendabot-api.titanci.com.br/v1/sse',
      );

      events.onmessage = event => {
        console.log('message event: ');
        const parsedData = JSON.parse(event.data);
        console.log('parsedData: ', parsedData);
        const { data } = parsedData;

        if (parsedData.event === 'disconnected') {
          setModalAutenticarWhatsApp(true);
          setQrCodeloading(true);
        } else if (parsedData.event === 'qr') {
          if (data !== '') {
            setModalAutenticarWhatsApp(true);
            setQrCodeloading(false);
            QRCode.toCanvas(document.getElementById('qrcode'), data, error => {
              if (error) console.error(error);
            });
          } else {
            console.error(
              'ops, o qr code está com problemas. Tente Novamente.',
            );
          }
        } else if (parsedData.event === 'auth_failure') {
          console.error(
            'ops, Aconteceu algum erro na autenticação do whatspp. Tente Novamente.',
          );
        } else {
          console.log('autenticado com sucesso');
          setModalAutenticarWhatsApp(false);
        }
      };
      setListening(true);
    }
  }, [listening]); */

  const columns = [
    {
      title: 'Título',
      key: 'title',
      dataIndex: 'title',
    },
    {
      title: 'Periodicidade',
      key: 'frequency',
      render: (record: DataSourceAgendamento) => (
        <>
          {record.frequency === 'daily' &&
            !record.deleteWeekend &&
            record.time &&
            `Todo dia, às ${record.time}.`}
          {record.frequency === 'daily' &&
            record.deleteWeekend &&
            record.time &&
            `Todo dia, às ${record.time}, exceto fins de semana.`}
          {record.frequency === 'weekly' &&
            record.time &&
            record.weekDay &&
            `${translateWeekday(record.weekDay)}, às ${record.time}.`}
          {record.frequency === 'monthly' &&
            record.time &&
            `Todo mês, dia ${getMonthDay(record.date)}, às ${record.time}.`}
          {record.frequency === 'yearly' &&
            record.time &&
            `Todo ano, dia ${getMonthDay(record.date)} de ${translateMonth(
              getMonth(record.date),
            )}, às ${record.time}.`}

          {record.frequency === 'single' &&
            record.time &&
            record.date &&
            `Uma única vez, dia ${record.date}, às ${record.time}.`}
        </>
      ),
    },
    {
      title: 'Status',
      key: 'pause',
      render: (record: DataSourceAgendamento) => (
        <>
          {record.activated ? (
            <Tag color="green">ativo</Tag>
          ) : (
            <Tag color="red">pausado</Tag>
          )}
        </>
      ),
    },
    {
      title: 'Ações',
      key: 'action',
      render: (record: DataSourceAgendamento) => (
        <Actions>
          <Button
            type="button"
            buttonSize="small"
            buttonType="primary"
            onClick={() => {
              setModalEdit(true);
              setAgendamentoSelected(record);
            }}
          >
            Editar
          </Button>
          <Button
            type="button"
            buttonSize="small"
            buttonType="danger"
            onClick={() => {
              setModalDelete(true);
              setAgendamentoSelected(record);
            }}
          >
            Deletar
          </Button>
        </Actions>
      ),
    },
  ];

  return (
    <>
      <AgendamentosContainer>
        <Search
          onChange={({ target: { value } }) =>
            debounceQuery(value, handleSearch)
          }
        />

        <AgendamentosContentButton>
          <Button
            type="button"
            buttonSize="large"
            buttonType="primary"
            onClick={() => setModalAdd(true)}
          >
            Novo agendamento
          </Button>

          {/* <Button
            type="button"
            buttonSize="large"
            buttonType="primary"
            onClick={() => setModalAutenticarWhatsApp(true)}
          >
            Autenticar WhatsApp
          </Button> */}
        </AgendamentosContentButton>

        <AgendamentosContentTable>
          <Table
            dataSource={agendamentosData}
            columns={columns}
            loading={loading}
            pagination={false}
            bordered
          />
          <Pagination
            currentPage={currentPage}
            total={agendamentosStore.agendamentosList?.pageCount ?? 1}
            onNextPage={e => setCurrentPage(e)}
            onPrevPage={e => setCurrentPage(e)}
          />
        </AgendamentosContentTable>
      </AgendamentosContainer>

      {modalAdd && (
        <ModalAddAgendamento
          visible={modalAdd}
          closeModal={() => setModalAdd(false)}
          fetch={() => fetchAgendamentosData(search)}
        />
      )}

      {modalEdit && (
        <ModalEditAgendamento
          visible={modalEdit}
          agendamento={agendamentoSelected}
          closeModal={() => setModalEdit(false)}
          fetch={() => fetchAgendamentosData(search)}
        />
      )}

      {modalDelete && (
        <ModalConfirmation
          visible={modalDelete}
          closeModal={() => setModalDelete(false)}
          title="Você tem certeza que desejar excluir o agendamento?"
          onClick={() => deleteAgendamento(agendamentoSelected?.key ?? '')}
          loading={loading}
        />
      )}

      {modalAutenticarWhatsApp && (
        <ModalAutenticarWhatsApp
          visible={modalAutenticarWhatsApp}
          closeModal={() => setModalAutenticarWhatsApp(false)}
          QrCodeLoading={QrCodeloading}
        />
      )}
    </>
  );
}

export const Agendamentos = observer(AgendamentosComp);
