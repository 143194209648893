export function getCreatedAtDateBrazilian(createdAt: string) {
  const yearAndMonth = createdAt.split('-');
  const day = yearAndMonth[2].split('T');

  return `${day[0]}/${yearAndMonth[1]}/${yearAndMonth[0]}`;
}

export function getFormatNumberList(number: string) {
  const withoutDDI = number.substring(2);

  const DDD = withoutDDI.substring(0, 2);
  const withoutDDD = withoutDDI.substring(2);

  const formatNumber = DDD.concat('9'.concat(withoutDDD));

  return formatNumber;
}

export function getDateBrazilian(date: string) {
  const dateBrazilian = date.split('-').reverse().join('/');

  return dateBrazilian ?? '';
}

export function getDateEnglish(date: string) {
  const dateBrazilian = date.split('/').reverse().join('-');

  return dateBrazilian ?? '';
}

export function getMonthDay(date: string) {
  const monthDay = date?.split('/')[0];
  return monthDay;
}

export function getMonth(date: string) {
  const month = date?.split('/')[1];
  return month;
}
